import React from "react";
const tailwindConfig = require("../../tailwind.config.js");

const colorTailWindMap: Record<string, string> = {
	picnic_grey: "#F8F5F2",
	picnic_black: "black",
	picnic_cyan: "#33ABAF",
	picnic_white: "white",
	"picnic-red": "#E1171E",
	"picnic-green": "rgba(136, 169, 30, 1)",
};

export const getBackGroundColor = (color?: string) => {
	const CSS: React.CSSProperties = {
		backgroundColor:
			color && colorTailWindMap[color] ? colorTailWindMap[color] : "",
	};
	return CSS;
};

export const getTailwindColorHex = (colorName) => {
	const colors = tailwindConfig.theme.colors;
	return colors[colorName] || null;
};