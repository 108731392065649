import RenderBlogContentComponent from "./components/BlogContent";
import RenderBlogsComponent from "./components/Blogs";
import RenderJobContent from "./components/JobContent";
import RenderWhatsAppApplyComponent from "./components/WhatsAppApply";

const ComponentMap = {
	blogs: {
		element: RenderBlogsComponent,
		context: ["blogs"],
	},
	blogContent: {
		element: RenderBlogContentComponent,
		context: ["blogs"],
	},
	whatsapp_apply: {
		element: RenderWhatsAppApplyComponent,
	},
	jobContent: {
		element: RenderJobContent,
    context: ['jobs']
	},
};

export default ComponentMap;
