'use client';

import React from "react";
import { useEffect, useState } from "react";

const Navigation = ({ content, mobileView }) => {
  const [activeTab, setActiveTab] = useState("");
  const [scrollSpyPosition, setScrollSpyPosition] = useState(0);
  const [scrollSpyHeight, setScrollSpyHeight] = useState(0);
  const [scrollSpyWidth, setScrollSpyWidth] = useState(0);

  useEffect(() => {
    const navButtonIds = content?.filter(row => row.heading).map(row => row.heading_id);

    setActiveTab(navButtonIds[0].heading_id);
    setScrollSpyWidth((window.scrollY) / (document.body.scrollHeight - window.innerHeight) * 100);

    const handleScroll = () => {
      setScrollSpyWidth((window.scrollY) / (document.body.scrollHeight - window.innerHeight) * 100);
      let lastHeadingScrolledPast = content[0]?.heading_id;
      for (const row of content) {
        const element = document.getElementById(row.heading_id);
        if (element && element.getBoundingClientRect().top <= 300) {
          lastHeadingScrolledPast = row.heading_id;
        }
      }
      
      const lastNavButton = document.getElementById(`key-${lastHeadingScrolledPast}`);
      if(lastNavButton) {
        setScrollSpyHeight(lastNavButton.getBoundingClientRect().height);
        const navContainer = lastNavButton?.parentElement?.getBoundingClientRect();
        const newPos = (lastNavButton?.getBoundingClientRect().top - navContainer?.top) || 0;
        setScrollSpyPosition(newPos);
        setActiveTab(lastHeadingScrolledPast);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToHeading = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };
  
  const RenderDesktop = () => {
    return (<div className={`relative z-10 mt-10 bg-white' : ''}`}>
      <div className="absolute left-0 top-0 h-full w-1 bg-green5 rounded-sm">
        <div 
          className="jobs-scrollspy absolute min-h-6 bg-green6 w-1 rounded-sm transition-transform duration-400 ease-in-out" 
          style={{height: `${scrollSpyHeight}px`, transform: `translateY(${scrollSpyPosition}px)`}}>
        </div>
      </div>
      
      <div className="relative ml-2 flex flex-col gap-4 items-start md:ml-0">
        {content.filter(row => row?.heading !== undefined && row.heading !== "&nbsp;").map((row: any) => (
          <button
            key={'key-'+row.heading_id}
            id={`key-${row.heading_id}`}
            onClick={()=> scrollToHeading(row.heading_id)}
            className={`px-3 text-left ${activeTab === row.heading_id ? "font-bold" : ""}`}
          >
            {row.heading}
          </button>
        ))}
      </div>
    </div>);
  }

  const RenderMobile = () => {
    return (
      <>
        <div className="absolute left-0 bottom-full h-1 w-full bg-green5">
          <div
            className="jobs-scrollspy absolute bg-green6 h-1 w-8 rounded-sm transition-transform duration-400 ease-in-out" 
            style={{width: `${scrollSpyWidth}%`}}></div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="hidden md:block">{RenderDesktop()}</div>
      <div className="md:hidden">{RenderMobile()}</div>
    </>
  );
};

export default Navigation;
