import tw from "tailwind-styled-components";
import { TailwindBGColorMap, responsiveDisplay } from "../../styles/common";
import { buttonData } from "./buttons.interfaces";

const ButtonSizeMap: Record<string, string> = {
	xxsm: "px-3 py-1",
	xsm: "px-4 py-1",
	sm: "w-32 h-11",
	md: "w-36 h-11",
	lg: "w-40 h-11",
	xl: "w-80 h-11",
  icon: "w-11 h-11",
	free: "",
	full: "w-full h-11",
};

type ButtonProps = {
	$styling: buttonData["styling"];
};

const StyledButton = tw.button<ButtonProps>`
    border-0 
    flex gap-2
    items-center 
    justify-center
    duration-200
    ease-in-out
    active:animate-[click_200ms_ease-in-out]
    mb-2 lg:mb-0
    ${(variant) => (variant.$styling?.padded ? "p-2.5" : "px-8 py-2.5")}
    ${(variant) => (variant.$styling?.zindex && `z-${variant.$styling.zindex}`)}
    ${(variant) => variant.$styling?.background_color && `bg-${variant.$styling.background_color}`}
    ${(variant) => variant.$styling?.border_color && variant.$styling.border_color != "transparent" &&	`border-${variant.$styling.border_color} border-2 rounded`}
    ${(variant) => variant.$styling?.rounded ? (variant.$styling?.padded ? "rounded-full p-4 px-8" : "rounded-full") : "" }
    ${(variant) => variant.$styling?.circle && "aspect-square"}
    ${(variant) => variant.$styling?.sizing !== undefined && ButtonSizeMap[variant.$styling.sizing]}
    ${(variant) => variant.$styling?.display !== undefined && responsiveDisplay[variant.$styling.display]}
    ${(variant) => variant.$styling?.ghost ? 
       `hover:bg-${variant.$styling.background_color} hover:text-white
        bg-transparent text-${variant.$styling.background_color}  
        border-[1px] border-${variant.$styling.background_color}`
       : 'hover:opacity-80'}
    ${(variant) => variant.$styling?.custom && variant.$styling.custom}
    ${(variant) => variant.$styling?.pill ? `
        text-white
        hover:bg-transparent hover:text-black
        bg-${variant.$styling.background_color}
        border-[1px] border-${variant.$styling.border_color} hover:opacity-100` : ''}
  `.withStyle((p) =>
	p.$styling?.background_image
		? {
				backgroundImage: `url(${p.$styling.background_image})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "contain",
		  }
		: {}
);

export default StyledButton;