"use client";

import React, { useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import RenderButton from "@/components/Button/partials/Button";
import RenderFormComponent from "@/shared/components/Form";
import { isMobile } from "@/shared/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { getCookie } from "@/shared/utils/gtm";

type ShareModalProps = {
	data: any;
	mobileView: boolean;
  context?: any;
};

const RenderJobFormModalComponent = ({ data, mobileView, context }: ShareModalProps) => {
  mobileView = isMobile();
  data[0].data.columns[0].components[0].data.style= {no_shadow:true};
  const [open, setOpen] = useState<Boolean>(false);
  const [minimised, setMinimised] = useState<Boolean>(false);
	const [copied, setCopied] = useState<Boolean>(false);
  const modalRef = useRef(null);

  const locale = getCookie('x-picnic-locale').split('-')[1] || 'en';

	const handleOpen = () => {
    setOpen(true)
    setMinimised(false)
  };

  const handleMinimise = () => {
    setMinimised(!minimised);
  }

	const handleClose = () => setOpen(false);

  const shareTextMap: Record<string, string> = {
    en: "Link copied to clipboard!",
    nl: "Link gekopieerd naar klembord!",
    de: "Link in die Zwischenablage kopiert!",
    fr: "Lien copié dans le presse-papiers !",
  }
  
  const mobileShareTextMap: Record<string, string> = {
    en: "Join us at Picnic!",
    nl: "Kom naar Picnic!",
    de: "Besuchen Sie uns beim Picknick!",
    fr: "Rejoignez-nous au pique-nique !",
  }

	const handleCopyToClipboard = () => {
    if(mobileView) {
      const jobTitle = data[0].data.columns[0].components[0].data
      navigator.share({title: jobTitle.settings.header_text, text: mobileShareTextMap[locale], url: window.location.href})
    } else {
      navigator.clipboard.writeText(window.location.href);
      setCopied(true);
      setTimeout(()=>{ setCopied(false) }, 3000);
    }
	};

  const RenderTooltip = () => {
    return (
      <span className="job-share-tooltip">
        <span className="tooltip-arrow"></span>
        <FontAwesomeIcon icon={faCheck} size="lg" className="text-black bg-grey1 rounded-full p-2"/> {shareTextMap[locale]}
      </span>
    )
  }

  return (
		<div className="formModal">
      <div className='flex items-center justify-center gap-4 z-[999]'>
        <RenderButton
          button={{
            text: "Apply Directly",
            target: "_self",
            onClick: handleOpen,
            styling: {
              sizing: "lg",
              background_color: "green1",
              rounded: true,
              circle: true,
              border_color: "green1",
              pill: true,
              custom: "py-2 px-2"
            },
          }}
          index={'job-apply-' + Math.random()*100}
        />
        <span className="relative">
          {copied && <RenderTooltip />}
          <RenderButton
            data-tooltip-id="clipboard-tooltip"
            button={{
              text: undefined,
              target: "_self",
              onClick: handleCopyToClipboard,
              icon: "https://d1gr3r269tafbs.cloudfront.net/uploads/sites/10/2024/11/share-icon.svg",
              styling: {
                sizing: "icon",
                background_color: "transparent",
                border_color: "grey2",
                zindex: 60,
                icon_size: 80,
                rounded: false,
                padded: true,
                custom: `text-grey3 hover:opacity-100 hover:bg-green1 hover:border-green1 modalbutton `
              },
            }}
            index={'job-share-' + Math.random()*100}
          />
        </span>
      </div>
			<Modal
				keepMounted
				open={open}
				onClose={handleClose}
				aria-labelledby="keep-mounted-modal-title"
				aria-describedby="keep-mounted-modal-description"
        sx={{ backdropFilter: `blur(${open && !minimised ? '2px' : '0px'})` }}
        slotProps={{backdrop: {style: { backgroundColor: "transparent"}}}}
        disableScrollLock
			>
				<div ref={modalRef} 
          className={`absolute bottom-0 left-0 transform w-full bg-white md:rounded-3xl rounded-b-none
            drop-shadow-card transition-max-height duration-700 ease-in-out 
            ${minimised ? `${mobileView ? "max-h-[76px] drop-shadow-none" : "max-h-20" }` : `max-h-full ${mobileView ? 'overflow-y-auto' : 'overflow-hidden'}`}`}
        >
          <RenderFormComponent data={data[0].data.columns[0].components[0].data} />
          <span className="absolute top-6 right-6 md:top-5 md:right-5 z-[999]">
            <RenderButton
              button={{
                text: "",
                target: "_self",
                icon: `https://d1gr3r269tafbs.cloudfront.net/uploads/sites/10/2024/11/${minimised ? "plus" : "minus"}-icon.svg`,
                onClick: handleMinimise,
                styling: {
                  sizing: "icon",
                  background_color: "transparent",
                  border_color: "grey2",
                  icon_size: 32,
                  zindex: 60,
                  rounded: false,
                  padded: true,
                  custom: `text-grey3 duration-150 hover:opacity-100 hover:bg-green1 hover:border-green1 modalbutton`
                },
              }}
              index={10}
            />
          </span>
				</div>
			</Modal>
		</div>
	);
};

export default RenderJobFormModalComponent;
