"use client";
import React, { useEffect, useRef } from "react";
import IngredientsAndInstructions from "../partials2/IngredientsAndInstructions";
import { formatMarkdown } from "../partials2/utils";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { activePrepTime, BackToCookbook, totalPrepTime } from "../partials2/text";
import { Link } from "@/shared/components/Link";
import Image from "next/image";
import { useContentScroll } from "../partials2/scroll";
import { slugify } from "@/shared/utils/string";

const RecipeContent = ({ recipe, lang, mobileView }: any) => {
    const contentRef = useRef(null);
    const containerRef = useRef(null);
    useContentScroll(contentRef, containerRef);
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const newPath = `/recipes/${recipe?.id}/${slugify(recipe?.name ?? "")}?${queryParams.toString()}`;
        window.history.replaceState(null, "", newPath);
    }, [recipe]);
    const RecipeTime = () => {
        const activeTime = recipe.active_preparation_time_in_minutes;
        const totalTime = recipe.preparation_time_in_minutes;
        return (
            <div className={"flex gap-1 text-green1 align-middle mb-2"}>
                <AccessTimeIcon
                    className={"my-auto"}
                    style={{
                        fontSize: 20,
                    }}
                />
                <span>
                    <small>
                        {activeTime && totalTime !== activeTime
                            ? `${activeTime} ${activePrepTime[
                            lang as keyof typeof activePrepTime
                            ]
                            } • ${totalTime} ${totalPrepTime[
                            lang as keyof typeof totalPrepTime
                            ]
                            }`
                            : `${totalTime} min`}
                    </small>
                </span>
            </div>
        );
    };

    const BackButton = () => {
		return (
			<div className="mb-6 top-[105px] md:top-auto z-50 absolute md:relative">
				<Link prefetch={true} href={`/${lang}/recipes`}>
					<div className="px-3 py-2 bg-grey1 rounded-full w-fit flex items-center gap-2">
						<Image
							width={24}
							height={24}
							src={
								"https://d1gr3r269tafbs.cloudfront.net/nextjs/jobs/arrow_1731415854.svg"
							}
							alt={`Back arrow`}
						/>
						<div className="leading-none align-text-bottom mt-0.5 text-grey4 font-mediun">
							{BackToCookbook[lang]}
						</div>
					</div>
				</Link>
			</div>
		);
	};
    return (
        <div
            ref={containerRef}
            className="flex flex-col md:flex-row md:h-[815px] text-grey5 md:w-1/2 h-auto m-auto"
        >
            <div
                ref={contentRef}
                className={
                    "flex-1 bg-white rounded-xl md:rounded-none -mt-4 md:mt-0 z-50 overflow-hidden"
                }
            >
                <div className={"p-6 md:p-10 p-12 md:pb-20 text-pretty"}>
                    <BackButton />
                    <RecipeTime />
                    <h2 className={"mb-2 md:mb-3"}>
                        <strong>{recipe.name}</strong>
                    </h2>
                    <h5>{formatMarkdown(recipe.description)}</h5>
                    <IngredientsAndInstructions
                        ingredients={recipe.ingredients}
                        instructions={recipe.preparation_instructions}
                        lang={lang}
                    />
                </div>
            </div>
        </div>
    );
};

export default RecipeContent;
