"use client";
export const ClientFilterLogic = () => {
	const recipes = document.querySelectorAll("#all-recipes .recipe");
	const updatedSearchParams = new URLSearchParams(window.location.search);
	const tab: string = updatedSearchParams?.get("tab")?.toUpperCase() ?? "";
	recipes.forEach((recipe) => {
		const categories = recipe?.getAttribute("data-categories")?.toUpperCase() ?? "";
		if (tab == "ALL_RECIPES") {
			const search = recipe.getAttribute("data-search") ?? "";
			if (
				search
					.toLowerCase()
					.includes(
						updatedSearchParams.get("search")?.toLowerCase() ?? ""
					)
			) {
				recipe.style.display = "block";
			} else {
				recipe.style.display = "none";
			}
		} else if (categories.includes(tab)) {
			recipe.style.display = "block";
		} else {
			recipe.style.display = "none";
		}
	});
}