"use client";
import React, { useState } from "react";
import RenderSearchBarComponent from "@/shared/components/SearchBar";
import { ClientFilterLogic } from "../client";

export const RecipeSearch = () => {
	const searchParams = new URLSearchParams(window.location.search);
	const [searchterm, setSearchTerm] = useState(
		searchParams.get("search") ?? ""
	);
	const setQuery = (search: string) => {
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.set("search", search);
		window.history.replaceState(
			null,
			"",
			`/recipes/?${searchParams.toString()}`
		);
		ClientFilterLogic();
		setSearchTerm(search);
	};
	const onClickSearch = () => {
		document.getElementById(`ALL_RECIPES-segment`)?.click();
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.set("tab", "ALL_RECIPES");
		window.history.replaceState(
			null,
			"",
			`/recipes/?${searchParams.toString()}`
		);
	};
	return (
		<RenderSearchBarComponent
			data={{
				value: searchterm,
				placeholder: "Zoek een recept",
				advanced: {
					enabled: true,
				},
				styling: {
					height: "h-10",
					bg_color: "bg-grey0 hover:bg-grey1",
					text_color: "text-grey4",
					border: "grey1",
				},
			}}
			setSearchTermExternal={setQuery}
			onClickExternal={onClickSearch}
		/>
	);
};

RecipeSearch.displayName = "RecipeSearch";
