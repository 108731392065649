"use client";
import React, { useRef, useEffect, useState } from "react";
import { SegmentSelectorProps } from "../types";
import Flicking from "@egjs/react-flicking";
import { ClientFilterLogic } from "../client";

export const SegmentSelector = ({
	segments,
	lang,
	mobileView,
}: SegmentSelectorProps) => {
	const flickingRef = useRef<Flicking>(null);
	const searchParams = new URLSearchParams(window.location.search);
	const [selectedSegment, setSelectedSegment] = useState(
		searchParams?.get("tab")?.toUpperCase() ?? "THIS_WEEK_RECIPES"
	);

	// Start the client side filter logic on load
	useEffect(() => {
		ClientFilterLogic();
	}, []);

	// Scroll to the selected segment initially and whenever it changes
	useEffect(() => {
		if (flickingRef.current && mobileView) {
			flickingRef.current.forceUpdate();
			const index = segments.findIndex(
				(segment) => segment.segment_type === selectedSegment
			);
			if (index !== -1 && flickingRef.current.panels) {
				const panel = flickingRef.current.panels[index];
				if (panel && panel.visibleRatio < 1) {
					flickingRef.current.moveTo(index, 1000);
				}
			}
		}
	}, [selectedSegment, segments, mobileView]);

	const handleSegmentChange = (segment: string) => {
		if (segment !== selectedSegment) {
			const searchParams = new URLSearchParams(window.location.search);
			searchParams.set("tab", segment);
			window.history.replaceState(
				null,
				"",
				`/recipes/?${searchParams.toString()}`
			);
			ClientFilterLogic();
			setSelectedSegment(segment);
		}
	};

	const renderSegments = () => {
		return segments.map((segment) => (
			<a
				key={segment.segment_type}
				className={`px-3 py-1 md:py-1.5 rounded text-sm transition-colors mr-3 cursor-pointer ${
					selectedSegment === segment.segment_type
						? "bg-green7 text-white"
						: "bg-grey0 border-grey1 border text-grey4 hover:bg-grey2 hover:border-grey2"
				}`}
				id={`${segment.segment_type}-segment`}
				onClick={() =>
					handleSegmentChange(segment.segment_type.toUpperCase())
				}
			>
				<span className="leading-none align-text-bottom">
					{segment.segment_name}
				</span>
			</a>
		));
	};

	return (
		<div className="w-full">
			{mobileView ? (
				<Flicking
					ref={flickingRef}
					align="prev"
					gap={10}
					circular={false}
					bound={true}
					useFindDOMNode={true}
				>
					{renderSegments()}
				</Flicking>
			) : (
				renderSegments()
			)}
		</div>
	);
};

SegmentSelector.displayName = "SegmentSelector";