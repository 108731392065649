"use client";

import React from "react";
import { Card as CardInterface } from "./card.interfaces";
import { Box, Card, CardContent } from "@mui/material";
import RenderButtonComponent from "../Button";
import RenderIconContent from "./partials/RenderIconContent";
import RenderCardText from "./partials/RenderCardText";
import RenderCardMedia from "./partials/RenderCardMedia";
import Link from "next/link";
import { ChevronRight } from "@mui/icons-material";
import RenderTextBlock from "../Text";
import RenderCheckList from "../List/variants/RenderCheckList";
import CardClassNames from "./card.styles";

type Props = {
	data: CardInterface;
	mobileView: boolean;
};

const RenderCardComponent = ({ data, mobileView }: Props) => {
	const {
		title,
		content,
		media_src,
		buttons,
		clickable,
		styling,
		iconned_content,
		checklist,
		highlight,
    numbered
	} = data;

  // console.log("Numbered card", numbered);

	const hasMedia =
		media_src?.desktop?.src != undefined && media_src?.desktop?.src != "";

	const textColor =
		styling?.text_color == undefined ? "black" : styling?.text_color;

	const textPosition =
		styling?.text_position == undefined ? "normal" : styling?.text_position;

	const cardClassList = CardClassNames(styling, clickable);

	const RenderCardContent = () => {
		return content && (
			<CardContent className={`${cardClassList.cardContent}`}>
				<div className={`${cardClassList.cardContentInnerDiv}`}>
					<div
						className={`flex flex-col gap-1 justify-center`}
						style={{
							paddingTop:
								mobileView && styling.image_position == "card"
									? "50px"
									: "0px",
						}}
					>
            		{numbered && ( <span className="bg-green1 rounded-full w-8 h-8 p-1 mb-4 mt-2 text-white text-center font-bold">{numbered}</span> )}
						<RenderCardText
							cardText={{
								content: content,
								textPosition: textPosition,
								textColor: textColor,
								textJustify: styling?.justify_text,
							}}
							buttons={buttons}
							styling={styling}
						/>
						{iconned_content && (
							<RenderIconContent content={iconned_content} />
						)}
						{checklist && (
							<div className={"pt-5"}>
								<RenderCheckList items={checklist} />
							</div>
						)}
					</div>

					{/* styling.separate_buttons determines if the buttons are rendered grouped with the text or not */}
					{styling?.separate_buttons != false && buttons && (
						<RenderButtonComponent data={{ buttons: buttons }} />
					)}

					{styling?.chevron_button && (
						<div className={`${cardClassList.cardChevron}`}>
							<ChevronRight
								className={`h-6 w-6 button`}
							></ChevronRight>
						</div>
					)}
				</div>
			</CardContent>
		);
	};

	const ClickWrapper = (props: { children?: React.ReactNode }) => {
		if (clickable && clickable.href !== "") {
			return (
				<Link href={clickable.href} prefetch={false}>
					{props.children}
				</Link>
			);
		}

		return <>{props.children}</>;
	};

	return (
		<ClickWrapper>
			<Box
				padding={styling?.no_padding !== true ? 3 : 0}
				className={`h-full w-full ${
					styling?.full_width !== true && "md:max-w-[600px]"
				}`}
			>
				<Card
					elevation={0}
					sx={{
						boxShadow: `${
							styling?.no_shadows
								? "none"
								: "0px 2px 8px 0px rgba(0, 0, 0, 0.12)"
						}`,
						position: "relative",
						borderRadius: `${
							styling?.rounded_size ? styling?.rounded_size : "16"
						}px`,
					}}
					className={`h-full ${cardClassList.card}`}
				>
					<div className={cardClassList.cardInnerDiv}>
						{hasMedia && (
							<RenderCardMedia
								media={media_src}
								styling={styling}
								mobileView={mobileView}
								highlight={highlight}
							/>
						)}
						{title && (
							<div className={`z-5 pt-4 `}>
								<RenderTextBlock data={{ content: title }} />
							</div>
						)}
					</div>

					<RenderCardContent />
				</Card>
			</Box>
		</ClickWrapper>
	);
};

export default RenderCardComponent;
