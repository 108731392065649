"use client";
import { Box, CardMedia } from "@mui/material";
import React from "react";
import Image from "next/image";
import { MediaSrc } from "../../../interfaces/common";
import { isMobile } from "../../../hooks";
import { Card } from "../card.interfaces";
import RenderMediaComponent from "../../Media";

type cardMediaProps = {
	media: MediaSrc["media_src"];
	styling: any;
	mobileView: boolean;
	highlight?: {
		text?: string;
		color?: string;
	};
};

const RenderBackgroundMedia = ({ media, styling, mobileView }: cardMediaProps) => (
	<div className="absolute h-full w-full">
		<RenderMediaComponent
			data={{
				media_src: media,
				styling: styling,
			}}
			mobileView={mobileView}
		/>
	</div>
);

const RenderTopMedia = ({ media, styling, mobileView }: cardMediaProps) => (
	<div className={`flex items-center justify-center bg-black h-full`}>
		<RenderMediaComponent
			data={{
				media_src: media,
				styling: styling,
			}}
			mobileView={mobileView}
		/>
	</div>
);

const RenderLeftMedia = ({ media, styling, mobileView }: cardMediaProps) => (
	<div className={`flex h-full w-full items-center justify-center bg-black`}>
		<RenderMediaComponent
			data={{
				media_src: media,
				styling: styling,
			}}
			mobileView={mobileView}
		/>
	</div>
);

const RenderBottomMedia = ({ media, styling, mobileView }: cardMediaProps) => (
	<RenderMediaComponent
		data={{
			media_src: media,
			styling: styling,
		}}
		mobileView={mobileView}
	/>
);

const RenderTitleMedia = ({ media, styling, mobileView }: cardMediaProps) => (
	<div
		className="ml-4 mr-4 mt-4 h-12 w-12 overflow-hidden rounded-full"
		style={{ backgroundColor: styling?.media_background_color }}
	>
		<RenderMediaComponent
			data={{
				media_src: media,
				styling: styling,
			}}
			mobileView={mobileView}
		/>
	</div>
);

const RenderIconMedia = ({ media, styling, mobileView }: cardMediaProps) => (
	<div className="w-6 bg-white ml-6">
		<RenderMediaComponent
			data={{ media_src: media, styling: styling }}
			mobileView={mobileView}
		/>
	</div>
);

const RenderRightMedia = ({media, styling, mobileView}: cardMediaProps) => (
  <div className="absolute z-10 right-4 top-4 max-w-24 max-h-28">
    <RenderMediaComponent
			data={{ media_src: media, styling: styling }}
			mobileView={mobileView}
    />
  </div>
);

const cardMediaMap: Record<string, React.FC<cardMediaProps>> = {
	background: RenderBackgroundMedia,
	top: RenderTopMedia,
	bottom: RenderBottomMedia,
	title: RenderTitleMedia,
	left: RenderLeftMedia,
	icon: RenderIconMedia,
  right: RenderRightMedia
};

const RenderCardMedia = ({ media, styling, mobileView, highlight }: cardMediaProps) => {
  const imagePosition = mobileView && styling?.image_position_mobile ? styling?.image_position_mobile : styling?.image_position;
	const MediaVariant = cardMediaMap[imagePosition] ? cardMediaMap[imagePosition] : cardMediaMap["top"];
	return (
		<div className={"relative h-full"}>
			<MediaVariant
				media={media}
				styling={styling}
				mobileView={mobileView}
				highlight={highlight}
			/>
			{highlight?.text && (
				<div className={`absolute bottom-3 left-4 rounded px-2 py-0.5 text-sm text-white bg-${ highlight.color ?? "red1" }`}>
					{highlight.text}
				</div>
			)}
		</div>
	);
};

export default RenderCardMedia;
